import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>Loader Logo</title>
    <g>
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 12, 2
                  L 3, 6
                  L 3, 18
                  L 12, 22
                  L 21, 18
                  L 21, 6 z"
      />
    </g>
  </svg>
);

export default IconLoader;
