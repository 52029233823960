import React from 'react';
import RockvilleSolidWoff from '@fonts/Rockville-Solid.woff';
import styled from 'styled-components';

const Style = styled.div`
  @font-face {
    font-family: 'RockvilleSolid';
    src: url(${RockvilleSolidWoff});
  }

  p {
    font-family: RockvilleSolid;
    color: #fff;
  }

  span {
    color: #e2421f;
  }
`;

const IconLogo = () => (
  <Style>
    <p>
      <span>Shinya</span>Kato
    </p>
  </Style>
);

export default IconLogo;
